<template>
  <div class="app">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>代理中心</el-breadcrumb-item>
          <el-breadcrumb-item>新增服务商</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <el-form ref="form" :model="form" :rules="rules">
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">基本信息</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="服务商类型"
              prop="serviceType"
              :rules="[
                {
                  required: true,
                  message: '请输入服务商类型',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="form.serviceType"
                placeholder="请选择"
                clearable
              >
                <el-option label="请选择" value=""></el-option>
                <el-option label="创客" value="1"></el-option>
                <el-option label="全国服务商" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="服务商名称"
              prop="serviceName"
              :rules="[
                {
                  required: true,
                  message: '请输入服务商名称',
                  trigger: 'blur',
                },
                {
                  min: 2,
                  max: 50,
                  message: '长度在 2 到 50 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input placeholder="" v-model="form.serviceName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="真实姓名"
              prop="userName"
              :rules="[
                { required: true, message: '请输入真实姓名', trigger: 'blur' },
                {
                  min: 2,
                  max: 20,
                  message: '长度在 2 到 20 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input placeholder="" v-model="form.userName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="身份证号"
              prop="idCardNo"
              :rules="[
                { required: true, message: '请输入身份证号', trigger: 'blur' },
                {
                  min: 15,
                  max: 18,
                  message: '长度在 15 到 18 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input placeholder="" v-model="form.idCardNo"></el-input>
            </el-form-item>
          </el-col>
          <!--<el-col :span="span" class="hight">
                        <el-form-item label="运营区域" prop="operationArea" :rules="[{required:true,message:'请选择运营区域',trigger: 'blur' }]">
                            <operation-area v-model="form.operationArea" @get-operation-area="getOperationArea" :show="disabled"></operation-area>
                        </el-form-item>
                    </el-col>-->
          <el-col :span="span" class="hight">
            <el-form-item
              label="手机号码（会员账号）"
              prop="phone"
              :rules="[
                {
                  required: true,
                  message: '请输入手机号码（会员账号）',
                  trigger: 'blur',
                },
                {
                  min: 11,
                  max: 11,
                  message: '长度在 11 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                @change="getUserinfo"
                placeholder=""
                v-model="form.phone"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="账号名称"
              prop="realName"
              :rules="[
                { required: true, message: '请输入账号名称', trigger: 'blur' },
              ]"
            >
              <el-input
                placeholder=""
                v-model="form.realName"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="办公区域"
              prop="officeArea"
              :rules="[
                { required: true, message: '请选择办公区域', trigger: 'blur' },
              ]"
            >
              <all-area-list
                v-model="form.officeArea"
                @get-all-area-list="getAllAreaList"
                :data="form.officeArea"
                :show="disabled || lock"
              ></all-area-list>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="状态"
              prop="enable"
              :rules="[
                { required: true, message: '请选择状态', trigger: 'blur' },
              ]"
            >
              <el-select v-model="form.enable" placeholder="请选择" clearable>
                <el-option
                  v-for="(rs, index) in enable"
                  :label="rs"
                  :value="index"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="hight">
            <el-form-item
              label="详细地址"
              prop="address"
              :rules="[
                { required: true, message: '请输入详细地址', trigger: 'blur' },
                {
                  min: 6,
                  max: 200,
                  message: '长度在 6 到 200 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input placeholder="" v-model="form.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row>
          <el-col :span="24">
            <span class="grid-divider">上传身份证</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="span">
            <upload
              v-if="showPic"
              @getUploadFile="getUploadFile"
              title="身份证人像面"
              field="card1"
              :param="upload.param"
              :urlPath="form.idCardImage ? form.idCardImage[0] : ''"
            ></upload>
          </el-col>
          <el-col :span="span">
            <upload
              v-if="showPic"
              @getUploadFile="getUploadFile"
              title="身份证国徽面"
              field="card2"
              :param="upload.param"
              :urlPath="form.idCardImage.length > 1 ? form.idCardImage[1] : ''"
            ></upload>
          </el-col>
          <el-col :span="span">
            <upload
              v-if="showPic"
              @getUploadFile="getUploadFile"
              title="手持身份证照"
              field="card3"
              :param="upload.param"
              :urlPath="form.idCardImage.length > 2 ? form.idCardImage[2] : ''"
            ></upload>
          </el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row>
          <el-col :span="24">
            <span class="grid-divider">上传营业执照</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="span">
            <upload
              v-if="showPic"
              @getUploadFile="getUploadFile"
              title="营业执照"
              field="businessLicenseImage"
              :param="upload.yyzz"
              :urlPath="
                form.businessLicenseImage ? form.businessLicenseImage[0] : ''
              "
            ></upload>
          </el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row>
          <el-col :span="24">
            <span class="grid-divider">上传办公场所（可不传）</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="span">
            <upload
              v-if="showPic"
              @getUploadFile="getUploadFile"
              title="办公地址门牌"
              field="officeAreaImage1"
              :param="upload.office"
              :urlPath="form.officeAreaImage ? form.officeAreaImage[0] : ''"
            ></upload>
          </el-col>
          <el-col :span="span">
            <upload
              v-if="showPic"
              @getUploadFile="getUploadFile"
              title="公司挂牌门照"
              field="officeAreaImage2"
              :param="upload.office"
              :urlPath="
                form.officeAreaImage.length > 1 ? form.officeAreaImage[1] : ''
              "
            ></upload>
          </el-col>
          <el-col :span="span">
            <upload
              v-if="showPic"
              @getUploadFile="getUploadFile"
              title="办公环境（含工位）"
              field="officeAreaImage3"
              :param="upload.office"
              :urlPath="
                form.officeAreaImage.length > 2 ? form.officeAreaImage[2] : ''
              "
            ></upload>
          </el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row>
          <el-col :span="24">
            <el-button icon="el-icon-check" type="primary" @click="back">
              取消
            </el-button>
            <el-button
              icon="el-icon-back"
              type="primary"
              @click="success('form')"
            >
              提交
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script>
import { baseUrl } from "@/plugins/api";
import allAreaList from "@/components/admin/allAreaList.vue";
import upload from "@/components/admin/upload.vue";

export default {
  components: {
    allAreaList,
    upload,
  },
  props: {},
  data() {
    return {
      rules: {
        idCardNo: [
          { required: true, message: "请输入证件号", trigger: "blur" },
          {
            pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
            message: "请输入正确的证件号",
          },
          { validator: this.idCardValidity, trigger: "blur" },
        ],
      },
      infoDetail: {},
      span: 6,
      disabled: false,
      actionUploadUrl: "",
      enable: {
        true: "开启",
        false: "关闭",
      },
      isEdit: true,
      form: {
        address: "",
        applyDate: "",
        approveDate: "",
        approveResult: "",
        businessLicenseImage: [],
        enable: "",
        id: "",
        idCardImage: [],
        idCardNo: "",
        latitude: "",
        longitude: "",
        officeArea: "",
        officeAreaCode: "",
        officeAreaImage: [],
        operationCenterId: "",
        operationCenterName: "",
        phone: "",
        realName: "",
        serviceName: "",
        state: 0,
        userId: "",
        userName: "",
        serviceType: "",
      },
      upload: {
        title_card1: "身份证人像面",
        title_card2: "",
        title_card3: "",
        param: { fileType: "identity" },
        yyzz: { fileType: "business" },
        office: { fileType: "office" },
      },
      showPic: false,
      lock: false
    };
  },
  methods: {
    async infoData() {
      const id = this.$route.query.id;
      const user_info = localStorage.getItem("user_info");
      const user = JSON.parse(user_info);
      this.form.userId = user.userId;
      if (id) {
        await this.$get(this.$api.serviceDetail, { id: id }).then((res) => {
          if (res.code == 1) {
            this.form = res.data;
            this.getUserinfo();
            if (this.form.enable) {
              this.form.enable = "true";
            } else {
              this.form.enable = "false";
            }
            this.disabled = true;
            this.lock = true;
            this.isEdit = true;
            this.showPic = true;
          }
        });
      } else {
        this.isEdit = false;
        this.showPic = true;
      }
    },
    back() {
      let id = this.$route.query.id;
      if (!this.isEmpty(id)) {
        this.$router.go(-1); //返回上一层
      } else {
        this.isEdit = true;
        this.disabled = true;
      }
    },
    success(formName) {
      if (this.form.idCardImage.length < 3) {
        this.$message({
          type: "warning",
          message: "请上传身份证图片！",
        });
        return false;
      }
      if (this.form.businessLicenseImage.length < 1) {
        this.$message({
          type: "warning",
          message: "请上传上传营业执照图片！",
        });
        return false;
      }
      //   if (this.form.officeAreaImage.length < 3) {
      //     this.$message({
      //       type: "warning",
      //       message: "请上传完整办公场所图片！",
      //     });
      //     return false;
      //   }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const url = this.$route.query.id
                ? this.$api.serviceUpdate
                : this.$api.serviceAdd;
              this.post(url, this.form);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          this.$message({
            type: "warning",
            message: "请填写完整信息！",
          });
          return false;
        }
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          location.reload();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    getOperationArea(data) {
      const val = data["code"];
      const name = data["name"];
      if (val && val.length > 0 && Array.isArray(val)) {
        const end = val.slice(-1);
        this.form.operationAreaCode = end[0];
        this.form.operationArea = name.join("/");
      }
    },
    getAllAreaList(data) {
      const val = data.code;
      const name = data["name"];
      if (val && val.length > 0 && Array.isArray(val)) {
        this.form.officeArea = name.join("/");
        this.form.officeAreaCode = val.pop();
      }
    },
    getUploadFile(val) {
      if (val.field == "card1") {
        this.form.idCardImage[0] = val.url;
      }
      if (val.field == "card2") {
        this.form.idCardImage[1] = val.url;
      }
      if (val.field == "card3") {
        this.form.idCardImage[2] = val.url;
      }
      if (val.field == "officeAreaImage1") {
        this.form.officeAreaImage[0] = val.url;
      }
      if (val.field == "officeAreaImage2") {
        this.form.officeAreaImage[1] = val.url;
      }
      if (val.field == "officeAreaImage3") {
        this.form.officeAreaImage[2] = val.url;
      }
      if (val.field == "businessLicenseImage") {
        this.form.businessLicenseImage[0] = val.url;
      }
    },
    isEmpty(a) {
      if (a == "" || a == null || a == undefined) {
        // "",null,undefined
        return false;
      }
      return true;
    },
    isEditBtn() {
      this.isEdit = false;
      this.disabled = false;
    },
    getUserinfo() {
      this.form.realName = "";
      if (this.$isEmpty(this.form.phone)) {
        return false;
      }
      this.$get(this.$api.getAccount + "/" + this.form.phone, {}).then(
        (res) => {
          if (res.code == 1) {
            this.form.realName = res.data.nickName;
            this.form.userId = res.data.id;
          } else {
            this.$message({
              type: "error",
              message: "账户获取信息不存在!",
            });
          }
        }
      );
    },
  },
  created() {
    this.actionUploadUrl = baseUrl + "system/file/upload";
    this.infoData();
  },
};
</script>
<style lang="scss" scoped>
.is-always-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.is-always-shadow .el-col {
  height: auto;
}

.el-card {
  background: #F2F6FC;
  text-align: right;
  margin-top: 30px;
}

.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.hight {
  height: 90px;
}

.el-col {
  border-radius: 4px;
}

.el-tab-pane {
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.sub-title {
  min-height: 26px;
}

.el-imag {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 178px;
}

.el-imag:hover {
  border-color: #409EFF;
}

.el-icon-picture-outline {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.orderStatus {
  font-size: large;
  color: #409EFF;
}

.right {
  text-align: center;
  line-height: 30px;
}

.admin_table_main_pagination {
  line-height: 40px;
  text-align: right;
}

.admin_table_main_pagination .total {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.admin_table_main_pagination .number {
  font-size: 18px;
  font-weight: bold;
  color: #ff0000;
}

.el-select {
  width: 100%;
}
</style>

